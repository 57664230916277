import { IconButton, InputBase, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import DataTable from "react-data-table-component"
import styled from "styled-components"
import colors from "../../../utils/style/colors"
import { formatStrToDate } from "../../../utils/utils"
import {
  faCircleCheck,
  faCrown,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"
import { Loader } from "../../../utils/Atoms"
import { AdminUser } from "../../../models/personalInfos"
import { useGetDirectoryWithPaginationQuery } from "../../../features/userSlice"
import SearchIcon from "@mui/icons-material/Search"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InternError from "../../../components/InternError"
import { useNavigate } from "react-router-dom"

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2rem;

  h1 {
    font-size: 1.4rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 575px) {
    padding: 2rem 0.5rem 2rem 2.5rem;
  }

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3rem;
    h1 {
      font-size: 1.7rem;
    }
  }
`

function Directory() {
  const paginationComponentOptions = {
    rowsPerPageText: "Utilisateurs par page",
    rangeSeparatorText: "de",
  }

  const columns: any = [
    {
      name: "Nom",
      selector: (row: AdminUser) => row.lastname,
      sortable: true,
      sortField: "lastname",
    },
    {
      name: "Prénom",
      selector: (row: AdminUser) => row.firstName,
      sortable: true,
      sortField: "firstName",
    },
    {
      name: "Mail",
      selector: (row: AdminUser) => row.email,
      sortable: true,
      sortField: "email",
      cell: (row: AdminUser) => (
        <span>
          {(row.roles?.includes("ROLE_ADMIN") ||
            row.roles?.includes("ROLE_SUPER_ADMIN")) && (
            <FontAwesomeIcon icon={faCrown} color="#e6c619" className="me-1" />
          )}
          {row.email}
        </span>
      ),
    },
    {
      name: "Dernière connexion",
      selector: (row: AdminUser) => row.lastConnection,
      sortable: true,
      sortField: "lastConnection",
      format: (row: AdminUser) =>
        formatStrToDate(row.lastConnection ?? "", "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Compte actif",
      selector: (row: AdminUser) => row.isActive,
      center: true,
      cell: (row: AdminUser) =>
        row.isActive ? (
          <FontAwesomeIcon icon={faCircleCheck} color="green" />
        ) : (
          <FontAwesomeIcon icon={faXmarkCircle} color="darkred" />
        ),
      sortable: true,
      sortField: "isActive",
    },
  ]

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState("")
  const [skip, setSkip] = useState(false)
  const navigate = useNavigate()

  const [order, setOrder] = useState({
    orderBy: "lastname",
    direction: "ASC",
  })

  const {
    data: usersPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetDirectoryWithPaginationQuery(
    {
      limit: perPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      search: searchTerm,
    },
    { skip: skip }
  )

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleRowClicked = (id: number) => {
    navigate(`/admin/users/${id}`)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSkip(false)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isError) {
    content = <InternError />
  } else if (isSuccess) {
    content = (
      <div className="w-100">
        <DataTable
          columns={columns}
          data={usersPaginate.items}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationTotalRows={usersPaginate.totalItems}
          paginationPerPage={perPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={(row: AdminUser) => handleRowClicked(row?.id)}
          onSort={handleSort}
          sortServer
          defaultSortFieldId={1}
          defaultSortAsc={true}
          responsive
          highlightOnHover
          pointerOnHover
          noDataComponent="Aucun utilisateur"
          progressComponent={<Loader></Loader>}
          paginationComponentOptions={paginationComponentOptions}
        ></DataTable>
      </div>
    )
  }
  return (
    <StyledDiv fluid>
      <h1>Gestion des utilisateurs</h1>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          maxWidth: 400,
          marginBottom: "1rem",
        }}
        className="ms-auto"
        onSubmit={(e: any) => {
          e.preventDefault()
          setSkip(false)
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, width: "100%" }}
          placeholder="Rechercher"
          inputProps={{ "aria-label": "rechercher" }}
          onChange={(e) => {
            setSearchTerm(e.target.value)
            setSkip(true)
          }}
        />
        <IconButton
          type="submit"
          sx={{ p: "10px" }}
          aria-label="recherche"
          onClick={() => setSkip(false)}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <div className="d-flex justify-content-center">{content}</div>
    </StyledDiv>
  )
}

export default Directory
