import {
  faArrowUpRightFromSquare,
  faCoins,
  faEuroSign,
  faPenAlt,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EscalatorWarning, Man } from "@mui/icons-material"
import {
  Breadcrumbs,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { Container, FloatingLabel, Form, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import ButtonPrimary from "../../../components/ButtonPrimary"
import {
  useEditCardOrderMutation,
  useEditOtherOrderMutation,
  useGetAdminOrderQuery,
} from "../../../features/orderSlice"
import {
  getOrderStatusLabel,
  getOrderTypeLabel,
  getStatusColor,
  OrderStatusType,
  OrderType,
} from "../../../models/order"
import colors from "../../../utils/style/colors"
import {
  cutDocTitle,
  formatPrice,
  formatStrToDate,
  statusOrderIsEditable,
} from "../../../utils/utils"
import logo from "../../../assets/logo-tac-bus.svg"
import { Loader } from "../../../utils/Atoms"
import InternError from "../../../components/InternError"
import ButtonSecondary from "../../../components/ButtonSecondary"
import {
  getPaiementTypeLabel,
  Paiement,
  PaiementType,
} from "../../../models/paiement"
import { AdminCardOrder } from "../../../models/adminCardOrder"
import Toastify from "toastify-js"
import { ResponseApiException } from "../../../models/responseApiType"
import ValidateModal from "../../../components/ValidateModal"
import { API } from "../../../utils/API"
import { Document } from "../../../models/document"
import { AdminOtherOrder } from "../../../models/adminOtherOrder"

const StyledDiv = styled(Container)`
  padding: 2rem 1rem 2rem 3rem;
  background-image: linear-gradient(
      90deg,
      rgba(247, 247, 247, 0.8),
      rgba(247, 247, 247, 0.8)
    ),
    url(${logo});
  background-repeat: no-repeat;
  background-size: 100%, 5rem;
  min-height: 100vh;
  background-position: left, bottom 0 right 1rem;
  @media (min-width: 768px) {
    background-size: 100%, 18rem;
    background-position: left, bottom 3rem right 1rem;
  }

  textarea:focus-visible {
    outline: none !important;
  }

  h1 {
    font-size: 1.5rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 0.9rem;
  color: ${colors.violet};
  padding: 0;
  text-align: left;

  &:hover {
    color: ${colors.blue};
  }
`

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #929292;
  &:hover {
    text-decoration: underline;
    color: ${colors.violet};
  }
`
const StyledSpan = styled.span`
  font-weight: bold;
  color: ${colors.darkViolet};
`

const Span = styled.span`
  font-weight: 400;
  color: ${colors.darkViolet};
`

const Status = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`
function OrderItem() {
  let { orderNumber } = useParams()
  const [isUpdate, setIsUpdate] = useState(false)
  const [statusIsEditable, setStatusIsEditable] = useState(false)
  const [isPayment, setIsPayment] = useState(false)
  const [isOrdered, setIsOrdered] = useState(false)
  const [isChequePaiement, setIsChequePaiement] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [status, setStatus] = useState<OrderStatusType | string>("")
  const [note, setNote] = useState<string>("")
  const [chequeNumber, setChequeNumber] = useState<string>("")
  const [paiement, setPaiement] = useState<string | null>(PaiementType.Cash)
  const [amountPayed, setAmountPayed] = useState<number | undefined>()
  const [amount, setAmount] = useState<number | undefined>()

  const [adminChequeNumber, setAdminChequeNumber] = useState<string>("")
  const [adminPaiementType, setAdminPaiementType] = useState<string | null>(
    PaiementType.Cheque
  )
  const [isAdminChequePaiement, setIsAdminChequePaiement] = useState(false)

  const handlePaiement = (
    event: React.MouseEvent<HTMLElement>,
    newPaiement: string | null
  ) => {
    setPaiement(newPaiement)
    if (newPaiement === PaiementType.Cheque) {
      setIsChequePaiement(true)
    } else {
      setIsChequePaiement(false)
    }
  }

  const {
    data: order = {},
    isLoading,
    isSuccess,
    isError,
  } = useGetAdminOrderQuery(orderNumber)

  const [editCardOrder, { isLoading: isEditLoading }] =
    useEditCardOrderMutation()

  const [editOtherOrder, { isLoading: isOtherEditLoading }] =
    useEditOtherOrderMutation()

  const handleDownload = (id: number, fileName: string) => {
    API.get(`documents/${id}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  const handleValidate = () => {
    if (order.parentOrder?.type === OrderType.Other) {
      let otherOrder = {
        id: order.id,
        note: note,
        status: status,
        amount: amount,
        payment: {
          type: paiement,
          chequeNumber: chequeNumber,
          amount: amountPayed,
        } as Paiement,
        /*  adminPayment: {
          type: adminPaiementType,
          chequeNumber: adminChequeNumber,
        } as Paiement,*/
      } as AdminOtherOrder

      editOtherOrder({ id: order.id, body: otherOrder })
        .unwrap()
        .then(() => {
          setIsUpdate(false)
          Toastify({
            text: "Les modifications ont bien été prises en compte",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
        .catch((error: ResponseApiException) => {
          console.error(error.data)
        })
        .finally(setShowConfirm(false))
    } else {
      let cardOrder = {
        id: order.id,
        note: note,
        status: status,
        payment: {
          type: paiement,
          chequeNumber: chequeNumber,
          amount: amountPayed,
        } as Paiement,
      } as AdminCardOrder

      editCardOrder({ id: order.id, body: cardOrder })
        .unwrap()
        .then(() => {
          setIsUpdate(false)
          Toastify({
            text: "Les modifications ont bien été prises en compte",
            duration: 8000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "linear-gradient(to right, #29aac4, #bd79e7)",
            },
            onClick: function () {}, // Callback after click
          }).showToast()
        })
        .catch((error: ResponseApiException) => {
          console.error(error.data)
        })
        .finally(setShowConfirm(false))
    }
  }

  useEffect(() => {
    if (isSuccess || !isUpdate) {
      let isEditable = statusOrderIsEditable(
        order?.parentOrder?.status,
        order?.parentOrder?.type !== OrderType.Other
      )
      setStatusIsEditable(isEditable)
      setStatus(order?.parentOrder?.status)
      setNote(order?.parentOrder?.note)
      let payments = order?.parentOrder?.payments
      let payment
      if (payments && payments.length > 0) {
        payment = payments[0]
      }
      setPaiement(payment?.type)
      setChequeNumber(payment?.chequeNumber)
      setAmountPayed(payment?.amount ?? order?.parentOrder?.amount)
      setAmount(order.parentOrder?.amount)
      setIsPayment(
        [OrderStatusType.Payed, OrderStatusType.Cashed].includes(
          order?.parentOrder?.status
        )
      )
      setIsChequePaiement(payment?.type === PaiementType.Cheque)

      setAdminPaiementType(order?.payment?.type)
      setAdminChequeNumber(order?.payment?.chequeNumber)
      setIsOrdered(
        order?.parentOrder?.type === OrderType.Other &&
          [
            OrderStatusType.Payed,
            OrderStatusType.Ordered,
            OrderStatusType.PendingPayment,
          ].includes(order?.parentOrder?.status)
      )
      setIsAdminChequePaiement(order?.payment?.type === PaiementType.Cheque)
    }
  }, [order, isSuccess, isUpdate])

  useEffect(() => {
    if (isUpdate) {
      if (status === OrderStatusType.Payed) {
        setIsPayment(true)
      } else {
        setIsPayment(false)
      }
      if (
        order?.parentOrder?.type === OrderType.Other &&
        [
          OrderStatusType.Payed.toString(),
          OrderStatusType.Ordered.toString(),
          OrderStatusType.PendingPayment.toString(),
        ].includes(status)
      ) {
        setIsOrdered(true)
      } else {
        setIsOrdered(false)
      }
    }
  }, [status])

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isSuccess) {
    content = (
      <Paper
        elevation={2}
        className="col-lg-6 col-md-8 p-2 p-md-4 d-flex flex-column gap-1"
      >
        <div>
          <StyledSpan>Type de commande :</StyledSpan>{" "}
          {getOrderTypeLabel(order.parentOrder?.type)}
        </div>
        {order.parentOrder?.type === OrderType.Other && (
          <div>
            <StyledSpan>Titre :</StyledSpan> {order.parentOrder?.title}
          </div>
        )}
        <div>
          <StyledSpan>Date de la commande :</StyledSpan>{" "}
          {formatStrToDate(order.parentOrder?.orderDate)}
        </div>
        <div>
          <StyledSpan>Salarié :</StyledSpan>{" "}
          {order.parentOrder?.owner?.firstName}{" "}
          {order.parentOrder?.owner?.lastname}
          <span className="ms-2">
            <Link
              to={`/admin/users/${order.parentOrder?.owner?.id}`}
              target="_blank"
              style={{ color: colors.blue, fontSize: "0.9rem" }}
            >
              Voir le profil&nbsp;&nbsp;
              <FontAwesomeIcon size="sm" icon={faArrowUpRightFromSquare} />
            </Link>
          </span>
        </div>
        <div className="my-1">
          <StyledSpan>Détails de la commande :</StyledSpan>
          {order.parentOrder?.type === OrderType.Other ? (
            <div className="ms-2 mt-1" style={{ whiteSpace: "pre-line" }}>
              {order.employeeMessage ?? "Aucun détail fourni"}
            </div>
          ) : order.parentOrder?.type === OrderType.Movie ? (
            <div className="ms-2 mt-1">
              <div>
                <Man sx={{ color: colors.orange, fontSize: "20px" }} />{" "}
                <span>Places adulte :</span> {order.adultQuantity}
              </div>
              <div>
                <EscalatorWarning
                  sx={{ color: colors.blue, fontSize: "20px" }}
                />{" "}
                <span>Places enfant :</span> {order.childQuantity}
              </div>
            </div>
          ) : (
            <div className="ms-2 mt-1">
              <div>
                <Man sx={{ color: colors.orange, fontSize: "20px" }} />{" "}
                <span>Cartes :</span> {order.adultQuantity}
              </div>
            </div>
          )}
        </div>
        {order.parentOrder?.type === OrderType.Other &&
          order?.documents &&
          order.documents.length > 0 && (
            <div className="my-1 mt-2">
              <StyledSpan>Pièce(s)-jointe(s) :</StyledSpan>
              <div className="d-flex flex-column gap-1 mt-1">
                {order.documents.map((doc: Document) => (
                  <StyledButton
                    onClick={() => handleDownload(doc.id, doc.path)}
                    key={doc.id}
                  >
                    {cutDocTitle(doc.title)}
                  </StyledButton>
                ))}
              </div>
            </div>
          )}
        <div className="my-1">
          <StyledSpan>
            Remarque ADMIN
            {isUpdate && (
              <span>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faPencilAlt} size="sm" color="#fb5a5a" />
                &nbsp;
              </span>
            )}
            :
          </StyledSpan>
          <Form.Control
            className="mt-1"
            plaintext={!isUpdate}
            readOnly={!isUpdate}
            as="textarea"
            placeholder="Aucune remarque"
            defaultValue={order.parentOrder?.note}
            onChange={(e) => setNote(e.target.value)}
            style={{
              background: "#8080801a",
              borderRadius: "4px",
              padding: "0.7rem",
            }}
          />
        </div>
        {order?.parentOrder?.canceledAt && (
          <div className="mt-2">
            <StyledSpan>Annulée le :</StyledSpan>{" "}
            {formatStrToDate(order?.parentOrder?.canceledAt)}
          </div>
        )}
        {order.orderedAt && (
          <div className="mt-2">
            <StyledSpan>Commandée le :</StyledSpan>{" "}
            {formatStrToDate(order.orderedAt)}
          </div>
        )}
        {order.receivedAt && (
          <div className="mt-2">
            <StyledSpan>Réceptionnée le :</StyledSpan>{" "}
            {formatStrToDate(order.receivedAt)}
          </div>
        )}
        {order?.parentOrder?.paidAt && (
          <div className="mt-2">
            <StyledSpan>Payée le :</StyledSpan>{" "}
            {formatStrToDate(order?.parentOrder?.paidAt)}
          </div>
        )}
        {order?.parentOrder?.cashedAt && (
          <div className="mt-2">
            <StyledSpan>Encaissée le :</StyledSpan>{" "}
            {formatStrToDate(order?.parentOrder?.cashedAt)}
          </div>
        )}
        <div className="mt-2">
          <StyledSpan>
            Statut
            {isUpdate && statusIsEditable && (
              <span>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faPencilAlt} size="sm" color="#fb5a5a" />
              </span>
            )}{" "}
            :
          </StyledSpan>
          {(!isUpdate || !statusIsEditable) && (
            <Status status={order.parentOrder?.status}>
              {getOrderStatusLabel(order.parentOrder?.status)}
            </Status>
          )}
          {isUpdate && statusIsEditable && (
            <Form.Select
              size="sm"
              onChange={(e) => setStatus(e.target.value)}
              defaultValue={order?.parentOrder?.status}
            >
              {order.parentOrder?.type === OrderType.Other ? (
                <Fragment>
                  {[
                    OrderStatusType.Ordered,
                    OrderStatusType.PendingPayment,
                  ].includes(order?.parentOrder?.status) && (
                    <option value={OrderStatusType.Ordered}>
                      {getOrderStatusLabel(OrderStatusType.Ordered)}
                    </option>
                  )}
                  {[
                    OrderStatusType.PendingPayment,
                    OrderStatusType.Ordered,
                  ].includes(order?.parentOrder?.status) && (
                    <option value={OrderStatusType.PendingPayment}>
                      {getOrderStatusLabel(OrderStatusType.PendingPayment)}
                    </option>
                  )}
                  {[
                    OrderStatusType.PendingPayment,
                    OrderStatusType.Payed,
                  ].includes(order?.parentOrder?.status) && (
                    <option value={OrderStatusType.Payed}>
                      {getOrderStatusLabel(OrderStatusType.Payed)}
                    </option>
                  )}
                  {order?.parentOrder?.status === OrderStatusType.ToHandle && (
                    <option value={OrderStatusType.Canceled}>
                      {getOrderStatusLabel(OrderStatusType.Canceled)}
                    </option>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <option value={OrderStatusType.ToHandle}>
                    {getOrderStatusLabel(OrderStatusType.ToHandle)}
                  </option>
                  <option value={OrderStatusType.Payed}>
                    {getOrderStatusLabel(OrderStatusType.Payed)}
                  </option>
                  <option value={OrderStatusType.Canceled}>
                    {getOrderStatusLabel(OrderStatusType.Canceled)}
                  </option>
                </Fragment>
              )}
            </Form.Select>
          )}
        </div>

        {isOrdered && (
          <div className="mt-2 d-flex flex-column">
            <StyledSpan>Commande du CSE :</StyledSpan>
            <ul>
              <li>
                <div>
                  <Span>Type de paiement :</Span>&nbsp;&nbsp;
                  {getPaiementTypeLabel(adminPaiementType ?? "")}
                </div>
              </li>
              {isAdminChequePaiement && (
                <li>
                  <div className="mt-1">
                    <Span>Numéro de chèque :</Span>&nbsp;&nbsp;
                    {adminChequeNumber}
                  </div>
                </li>
              )}
              <li>
                <div className="mt-1">
                  <Span>Total commande CSE:</Span>&nbsp;&nbsp;
                  {formatPrice(order?.payment?.amount)}
                </div>
              </li>
            </ul>
          </div>
        )}

        {isPayment && !isUpdate && (
          <div className="mt-2 d-flex flex-column">
            <div>
              <StyledSpan>Type de paiement :</StyledSpan>&nbsp;&nbsp;
              {getPaiementTypeLabel(paiement ?? "")}
            </div>
            {isChequePaiement && (
              <div className="mt-1">
                <StyledSpan>Numéro de chèque :</StyledSpan>&nbsp;&nbsp;
                {chequeNumber}
              </div>
            )}
          </div>
        )}
        {isPayment && isUpdate && (
          <div className="mt-2 d-flex flex-column">
            <StyledSpan>
              Type de paiement :
              <span>
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faPencilAlt} size="sm" color="#fb5a5a" />
              </span>
            </StyledSpan>
            <ToggleButtonGroup
              value={paiement}
              exclusive
              aria-required
              onChange={handlePaiement}
              aria-label="type de paiement"
              aria-readonly={true}
            >
              <ToggleButton value={PaiementType.Cash} aria-label="en espèces">
                {getPaiementTypeLabel(PaiementType.Cash)}
              </ToggleButton>
              <ToggleButton value={PaiementType.Cheque} aria-label="par chèque">
                {getPaiementTypeLabel(PaiementType.Cheque)}
              </ToggleButton>
            </ToggleButtonGroup>
            {isChequePaiement && (
              <FloatingLabel controlId="amountRepayed" label="N° de chèque">
                <Form.Control
                  className="mt-1"
                  placeholder="Numéro de chèque"
                  defaultValue={chequeNumber}
                  onChange={(e) => setChequeNumber(e.target.value)}
                />
              </FloatingLabel>
            )}
            <FloatingLabel controlId="amountRepayed" label="Montant payé">
              <Form.Control
                className="mt-1"
                placeholder="Montant payé"
                required
                type="number"
                step={0.01}
                min={0}
                disabled={order?.parentOrder?.status === OrderStatusType.Cashed}
                defaultValue={amountPayed ?? order?.parentOrder?.amount}
                onChange={(e) => {
                  let value = parseFloat(e.target.value)
                  setAmountPayed(isNaN(value) ? undefined : value)
                }}
              />
            </FloatingLabel>
          </div>
        )}

        <div className="mt-2">
          <FontAwesomeIcon icon={faCoins} color={colors.violet} />
          &nbsp;&nbsp;
          <StyledSpan>
            Montant total : {formatPrice(order.parentOrder?.amount)}{" "}
            {isUpdate &&
              order?.parentOrder?.type === OrderType.Other &&
              order?.parentOrder?.status === OrderStatusType.ToHandle && (
                <span>
                  &nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    size="sm"
                    color="#fb5a5a"
                  />
                  &nbsp;
                </span>
              )}
          </StyledSpan>
          {isUpdate &&
            order?.parentOrder?.type === OrderType.Other &&
            order?.parentOrder?.status === OrderStatusType.ToHandle && (
              <div className="mt-2">
                <FloatingLabel controlId="amount" label="Montant total">
                  <Form.Control
                    className="mt-1"
                    placeholder="Montant total"
                    required
                    type="number"
                    step={0.01}
                    min={0}
                    defaultValue={amountPayed ?? order?.parentOrder?.amount}
                    onChange={(e) => {
                      let value = parseFloat(e.target.value)
                      setAmount(isNaN(value) ? undefined : value)
                    }}
                  />
                </FloatingLabel>
              </div>
            )}
        </div>
        {isPayment && !isUpdate && (
          <div className="mt-1">
            <FontAwesomeIcon icon={faEuroSign} color="#00adc0" />
            &nbsp;&nbsp;
            <StyledSpan style={{ color: "#00adc0" }}>
              Montant payé : {formatPrice(amountPayed)}
            </StyledSpan>
          </div>
        )}
        <div className="d-flex flew-wrap justify-content-end gap-1 mt-3">
          {!isUpdate && (
            <ButtonPrimary
              label="Modifier"
              icon={faPenAlt}
              onClick={() => setIsUpdate(true)}
            />
          )}
          {isUpdate && (
            <Fragment>
              <ButtonPrimary
                label="Valider"
                onClick={() => setShowConfirm(true)}
                loading={isEditLoading || isOtherEditLoading}
              />
              <ButtonSecondary
                label="Annuler"
                onClick={() => {
                  setIsUpdate(false)
                }}
              />
            </Fragment>
          )}
        </div>
      </Paper>
    )
  } else if (isError) {
    content = <InternError />
  }

  return (
    <StyledDiv fluid>
      <Breadcrumbs aria-label="breadcrumb" className="mb-4">
        <LinkStyled color="gray" to="/admin/orders">
          Commandes
        </LinkStyled>
        <Typography color="text.primary">N° {orderNumber}</Typography>
      </Breadcrumbs>
      <h1>Commande {orderNumber}</h1>
      <Row className="justify-content-center">{content}</Row>
      <ValidateModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        modalMessage="Êtes-vous sûr de vouloir modifier cette commande ?"
        modalTitle="Modification d'une commande"
        handleConfirm={handleValidate}
        loading={isEditLoading}
      />
    </StyledDiv>
  )
}

export default OrderItem
