import { Form, Modal, Row } from "react-bootstrap"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Toastify from "toastify-js"
import frLocale from "date-fns/locale/fr"
import {
  faBox,
  faInfoCircle,
  faQuestionCircle,
  faWarning,
} from "@fortawesome/free-solid-svg-icons"
import {
  useGetOtherOrdersByStatusQuery,
  useGetOtherOrdersTotalMutation,
  usePostAdminOtherOrderMutation,
} from "../../features/orderSlice"
import DataTable from "react-data-table-component"
import { Loader } from "../../utils/Atoms"
import { formatPrice, formatStrToDate } from "../../utils/utils"
import { OtherOrderAdmin } from "../../models/orderAdmin"
import {
  getOrderStatusLabel,
  getStatusColor,
  OrderStatusType,
} from "../../models/order"
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material"
import {
  getPaiementTypeLabel,
  Paiement,
  PaiementType,
} from "../../models/paiement"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import ValidateModal from "../ValidateModal"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .prices span,
  .total span {
    font-weight: bold;
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

const StyledSpan = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  white-space: nowrap;
  text-align: center;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`

const Span = styled.span`
  font-weight: bold;
  color: ${colors.darkViolet};
`

function OrderedModal(modalProps: ModalProps) {
  const [validated, setValidated] = useState(false)
  const [skip, setSkip] = useState(true)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [perPage, setPerPage] = useState(20)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [total, setTotal] = useState<number>(0)
  const [isAdminChequePaiement, setIsAdminChequePaiement] = useState(true)
  const [adminChequeNumber, setAdminChequeNumber] = useState<string>("")
  const [detail, setDetail] = useState<string>("Réglement commande de parfums")
  const [adminPaiementType, setAdminPaiementType] = useState<string | null>(
    PaiementType.Cheque
  )

  const [orderDate, setOrderDate] = useState<Date | null>(new Date())
  const [showConfirm, setShowConfirm] = useState(false)

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  useEffect(() => {
    setShowConfirm(false)
    if (modalProps.show) {
      setSkip(false)
      setValidated(false)
      setMsgError("")
      setErrors({})
    }
  }, [modalProps.show])

  const paginationComponentOptions = {
    rowsPerPageText: "Commandes par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Date commande",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.orderDate,
      sortable: true,
      format: (row: OtherOrderAdmin) =>
        formatStrToDate(row.parentOrder?.orderDate, "dd/MM/yyyy"),
    },
    {
      name: "Numéro",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.number,
    },
    {
      name: "Salarié",
      sortable: true,
      selector: (row: OtherOrderAdmin) => row.parentOrder?.owner,
      format: (row: OtherOrderAdmin) => {
        if (
          !row.parentOrder?.owner.firstName &&
          !row.parentOrder?.owner.lastname
        )
          return row.parentOrder?.owner.email
        else
          return `${row.parentOrder?.owner.firstName} ${row.parentOrder?.owner.lastname}`
      },
    },
    {
      name: "Libellé",
      sortable: true,
      selector: (row: OtherOrderAdmin) => row.parentOrder?.title,
    },
    {
      name: "Montant",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.amount,
      format: (row: OtherOrderAdmin) => formatPrice(row.parentOrder?.amount),
    },
    {
      name: "Statut",
      selector: (row: OtherOrderAdmin) => row.parentOrder?.status,
      cell: (row: OtherOrderAdmin) => (
        <StyledSpan status={row.parentOrder?.status}>
          {getOrderStatusLabel(row.parentOrder?.status)}
        </StyledSpan>
      ),
    },
  ] as any

  const {
    data: orders = [],
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetOtherOrdersByStatusQuery(OrderStatusType.ToHandle, { skip: skip })

  const [getTotal, { isLoading: isTotalLoading }] =
    useGetOtherOrdersTotalMutation()

  const [postAdminOtherOrder, { isLoading: isPostLoading }] =
    usePostAdminOtherOrderMutation()

  const handleRowSelected = ({ selectedRows }: any) => {
    setSelectedOrders(selectedRows)
  }

  const handleAdminPaiement = (
    event: React.MouseEvent<HTMLElement>,
    newPaiement: string | null
  ) => {
    setAdminPaiementType(newPaiement)
    if (newPaiement === PaiementType.Cheque) {
      setIsAdminChequePaiement(true)
    } else {
      setIsAdminChequePaiement(false)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getTotal({
        body: selectedOrders.map((item: OtherOrderAdmin) => item.id),
      })
        .unwrap()
        .then((resp: any) => setTotal(parseFloat(resp)))
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [selectedOrders])

  const handleValidate = () => {
    let postAdmin = {
      ids: selectedOrders.map((item: OtherOrderAdmin) => item.id),
      adminPayment: {
        type: adminPaiementType,
        chequeNumber: adminChequeNumber,
      } as Paiement,
      detail: detail,
      orderDate: orderDate ? format(orderDate, "yyyy-MM-dd HH:mm:ss") : null,
    }

    console.log(postAdmin)
    //post paiement and ids => set to ordered status and add paiement to other orders
    postAdminOtherOrder({
      body: postAdmin,
    })
      .unwrap()
      .then(() => {
        Toastify({
          text: "La commande a bien été créée",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        modalProps.handleClose()
      })
      .catch((error: ResponseApiException) => {
        console.error(error)
        if (error.data?.type === ResponseApiType.FormError) {
          setMsgError("Veuillez vérifier les données")
          setErrors(error.data?.detail)
          setValidated(true)
        } else if (error.data?.type === ResponseApiType.CustomError) {
          setMsgError(error.data?.detail)
        } else {
          setMsgError("Une erreur interne s'est produite.")
        }
      })
      .finally(() => {
        setShowConfirm(false)
      })
  }

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width p-0"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title className="d-flex gap-2 pt-1 align-items-center align-items-sm-start">
            <FontAwesomeIcon icon={faBox} color="white" />
            <h2>Commande passée par le CSE</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
              e.preventDefault()
              setShowConfirm(true)
            }}
          >
            <Row className="mb-3 ps-2">
              Veuillez sélectionner les commandes des employés correspondantes :
            </Row>
            <div className="mb-3 ">
              <FontAwesomeIcon icon={faInfoCircle} color={colors.violet} />{" "}
              <span className="fst-italic" style={{ color: "grey" }}>
                Une fois que vous aurez validé, toutes les commandes
                sélectionnées passeront automatiquement au statut commandée
              </span>
            </div>
            <Row>
              {isSuccess && (
                <DataTable
                  columns={columns}
                  data={orders}
                  progressPending={isLoading}
                  pagination
                  striped
                  dense
                  onChangeRowsPerPage={handlePerRowsChange}
                  paginationPerPage={perPage}
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                  responsive
                  selectableRows
                  onSelectedRowsChange={handleRowSelected}
                  highlightOnHover
                  noDataComponent="Aucune commande à traiter"
                  progressComponent={<Loader></Loader>}
                  paginationComponentOptions={paginationComponentOptions}
                ></DataTable>
              )}
            </Row>
            <Row>
              <div
                className="mt-2 mb-1 ps-2 d-flex flex-wrap gap-1 gap-md-2"
                style={{ fontWeight: "bold" }}
              >
                Montant Total :{" "}
                {isTotalLoading ? (
                  <Loader />
                ) : total ? (
                  formatPrice(total)
                ) : (
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color={colors.violet}
                  />
                )}
              </div>
              <div className="mb-3 ps-2">
                <FontAwesomeIcon icon={faWarning} color="darkred" />
                &nbsp; Veillez bien à vérifier que le montant correspond à la
                commande que vous avez réalisée
              </div>
            </Row>
            <Row>
              <div className="ps-2 mb-3 d-flex flex-column gap-1">
                <Span>
                  Type de paiement : *{" "}
                  {errors && errors.adminPayment && "Ce champ est requis"}
                </Span>
                <ToggleButtonGroup
                  value={adminPaiementType}
                  exclusive
                  aria-required
                  onChange={handleAdminPaiement}
                  aria-label="type de paiement"
                >
                  <ToggleButton
                    value={PaiementType.Cash}
                    aria-label="en espèces"
                  >
                    {getPaiementTypeLabel(PaiementType.Cash)}
                  </ToggleButton>
                  <ToggleButton
                    value={PaiementType.Cheque}
                    aria-label="par chèque"
                  >
                    {getPaiementTypeLabel(PaiementType.Cheque)}
                  </ToggleButton>
                </ToggleButtonGroup>
                {isAdminChequePaiement && (
                  <Form.Control
                    className="mt-1"
                    placeholder="Numéro de chèque"
                    defaultValue={adminChequeNumber}
                    onChange={(e) => setAdminChequeNumber(e.target.value)}
                  />
                )}
              </div>
            </Row>
            <Form.Group className="mb-4" controlId="formDetail">
              <Form.Label>
                <Span>Intitulé : * </Span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Indiquez un intitulé"
                value={detail}
                isInvalid={errors && errors.detail}
                onChange={(e) => setDetail(e.target.value)}
              />
              <Form.Text className="text-muted">
                L'intitulé correspond à celui qui apparaîtra dans la synthèse du
                budget social CSE
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.detail}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mb-3 px-2">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <DatePicker
                  label="Date de la commande"
                  value={orderDate}
                  onChange={(newValue) => {
                    setOrderDate(newValue)
                  }}
                  className="datePicker"
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors && errors.orderDate && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors &&
                    errors.orderDate?.map((error: any, index: number) => (
                      <div key={index}>{error}</div>
                    ))}
                </Form.Control.Feedback>
              )}
            </Row>

            <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary
                label="Valider"
                type="submit"
                disabled={!total || total <= 0}
                // loading={isPostLoading}
              />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
                type="button"
              />
            </div>
            <ValidateModal
              show={showConfirm}
              handleClose={() => setShowConfirm(false)}
              modalMessage="Êtes-vous sûr de vouloir ajouter cette commande ?"
              modalTitle="Ajout d'une commande"
              handleConfirm={handleValidate}
              loading={isPostLoading}
            />
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default OrderedModal
