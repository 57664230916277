import { TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import DataTable from "react-data-table-component"
import styled from "styled-components"
import colors from "../../../utils/style/colors"
import {
  formatPrice,
  formatStrToDate,
  getFirstDayOfCurrentYear,
  getLastDayOfCurrentYear,
} from "../../../utils/utils"
import frLocale from "date-fns/locale/fr"

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { Loader } from "../../../utils/Atoms"
import InternError from "../../../components/InternError"
import { useGetBankDepositWithPaginationQuery } from "../../../features/bankDepositSlice"
import { ParentOrder } from "../../../models/orderAdmin"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"
import BankDeposit from "../../../models/bankDeposit"
import ButtonPrimary from "../../../components/ButtonPrimary"
import BankDepositModal from "../../../components/BankDepositModal"
import { useNavigate } from "react-router-dom"

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2rem;

  h1 {
    font-size: 1.4rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }

  @media all and (min-width: 575px) {
    padding: 2rem 0.5rem 2rem 2.5rem;
  }

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3rem;
    h1 {
      font-size: 1.7rem;
    }
  }
`

const StyledChequeNumber = styled.span`
  font-size: 0.8rem;
  color: ${colors.violet};
  font-weight: bold;
`

const StyledRow = styled(Row)`
  font-size: 1rem;
  h3 {
    font-size: 1.1rem !important;
    color: ${colors.darkViolet};
    margin-bottom: 0 !important ;
  }
`

function BankDepositPage() {
  const paginationComponentOptions = {
    rowsPerPageText: "Éléments par page",
    rangeSeparatorText: "de",
  }

  const columns: any = [
    {
      name: "Date",
      selector: (row: BankDeposit) => row.depositedAt,
      sortable: true,
      sortField: "depositedAt",
      format: (row: BankDeposit) =>
        formatStrToDate(row.depositedAt ?? "", "dd/MM/yyyy HH:mm"),
    },
    {
      name: "Espèces",
      selector: (row: BankDeposit) => row.totalCash,
      format: (row: BankDeposit) => formatPrice(row.totalCash),
    },
    {
      name: "Chèque",
      selector: (row: BankDeposit) => row.totalCheque,
      cell: (row: BankDeposit) =>
        row.totalCheque && row.totalCheque > 0 ? (
          <div className="d-flex flex-column justify-content-center text-center">
            <span>{formatPrice(row.totalCheque)}</span>
            {row.chequeNumber && (
              <StyledChequeNumber>{row.chequeNumber}</StyledChequeNumber>
            )}
          </div>
        ) : (
          ""
        ),
    },
    {
      name: "Total",
      cell: (row: BankDeposit) =>
        formatPrice((row.totalCheque ?? 0) + (row.totalCash ?? 0)),
    },
  ]

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [from, setFrom] = useState<Date | null>(getFirstDayOfCurrentYear())
  const [to, setTo] = useState<Date | null>(getLastDayOfCurrentYear())
  const [fromDate, setFromDate] = useState<string | null>()
  const [toDate, setToDate] = useState<string | null>()
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const [order, setOrder] = useState({
    orderBy: "depositedAt",
    direction: "DESC",
  })

  const {
    data: bankPaginate = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetBankDepositWithPaginationQuery(
    {
      limit: perPage,
      page: page,
      orderBy: order.orderBy,
      direction: order.direction,
      from: fromDate,
      to: toDate,
    },
    { skip: !fromDate || !toDate }
  )

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  const handleSort = (selectedColumn: any, sortDirection: any) => {
    setOrder({
      orderBy: selectedColumn.sortField,
      direction: sortDirection,
    })
  }

  const handleRowClicked = (id: number) => {
    navigate(`/admin/bank-deposit/${id}`)
  }
  useEffect(() => {
    try {
      let date1 = from ? format(from, "yyyy-MM-dd HH:mm:ss") : null
      let date2 = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
      setFromDate(date1)
      setToDate(date2)
    } catch (error) {
      console.error(error)
    }
  }, [from, to])

  let content

  if (isLoading) {
    content = <Loader />
  } else if (isError) {
    content = <InternError />
  } else if (isSuccess) {
    content = (
      <div className="w-100">
        <DataTable
          columns={columns}
          data={bankPaginate.items}
          progressPending={isLoading}
          pagination
          striped
          paginationServer
          paginationTotalRows={bankPaginate.totalItems}
          paginationPerPage={perPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onRowClicked={(row: ParentOrder) => handleRowClicked(row?.id)}
          onSort={handleSort}
          sortServer
          defaultSortFieldId={1}
          defaultSortAsc={false}
          responsive
          highlightOnHover
          pointerOnHover
          noDataComponent="Aucune remise en banque"
          progressComponent={<Loader></Loader>}
          paginationComponentOptions={paginationComponentOptions}
        ></DataTable>
      </div>
    )
  }
  return (
    <StyledDiv fluid>
      <h1>Remises en banque</h1>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={frLocale}
      >
        <StyledRow className="px-lg-2 py-3 m-lg-1 my-3">
          <div className="d-flex flex-wrap gap-5 justify-content-center">
            <DatePicker
              label="Du"
              value={from}
              onChange={(newValue) => {
                setFrom(newValue)
              }}
              className="datePicker"
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "jj/mm/aaaa",
                  }}
                />
              )}
            />
            <DatePicker
              label="au"
              value={to}
              onChange={(newValue) => {
                setTo(newValue)
              }}
              className="datePicker"
              minDate={from}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "jj/mm/aaaa",
                  }}
                />
              )}
            />
          </div>
        </StyledRow>
      </LocalizationProvider>
      <div className="d-flex justify-content-start px-lg-5">
        <ButtonPrimary
          label="Ajouter"
          icon={faPlusCircle}
          onClick={() => setShowModal(true)}
        />
      </div>
      <div className="d-flex justify-content-center px-lg-5">{content}</div>
      <BankDepositModal
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </StyledDiv>
  )
}

export default BankDepositPage
