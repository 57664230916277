import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import frLocale from "date-fns/locale/fr"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { TextField, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useEffect, useState } from "react"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { format } from "date-fns"
import CardStockOrder, { StockOrderType } from "../../models/cardStockOrder"
import {
  useAddCardStockOrderMutation,
  useEditCardStockOrderMutation,
  useGetCardStockOrderQuery,
} from "../../features/orderSlice"
import {
  getPaiementTypeLabel,
  Paiement,
  PaiementType,
} from "../../models/paiement"
import { getOrderTypeLabel } from "../../models/order"
import { formatPrice } from "../../utils/utils"
import { API } from "../../utils/API"
import { Loader } from "../../utils/Atoms"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWarning } from "@fortawesome/free-solid-svg-icons"

interface ModalProps {
  show: boolean
  handleClose: () => void
  isUpdate?: boolean
  stockOrderId?: number
}

const Span = styled.span`
  font-weight: bold;
  color: ${colors.darkViolet};
`

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  .modal-title {
    font-size: 1rem !important;
    font-weight: 300 !important;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem !important;
    }
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

function CardStockOrderModal(modalProps: ModalProps) {
  const init = {
    type: StockOrderType.Pool,
  } as Partial<CardStockOrder>
  const [validated, setValidated] = useState(false)
  const [cardOrder, setCardOrder] = useState<Partial<CardStockOrder>>(init)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [isChequePaiement, setIsChequePaiement] = useState(true)
  const [total, setTotal] = useState<number | null>(0)
  const [totalWithDeposit, setTotalWithDeposit] = useState<number | null>(0)

  const [deposit, setDeposit] = useState<number | null>(0)
  const [chequeNumber, setChequeNumber] = useState<string>("")
  const [paiementType, setPaiemenType] = useState<string | null>(
    PaiementType.Cheque
  )
  const [orderPriceInfoLoading, setOrderPriceInfoLoading] = useState(false)
  const [orderPriceInfoSuccess, setOrderPriceInfoSuccess] = useState(false)
  const [orderPriceInfoError, setOrderPriceInfoError] = useState<any>(null)
  const [orderDate, setOrderDate] = useState<Date | null>(new Date())
  const [skip, setSkip] = useState(true)

  const {
    data: stockOrder,
    isSuccess,
  }: { data: CardStockOrder; isSuccess: boolean } = useGetCardStockOrderQuery(
    modalProps.stockOrderId,
    { skip }
  )

  const [addCardStockOrder, { isLoading: isPostLoading }] =
    useAddCardStockOrderMutation()

  const [editCardStockOrder, { isLoading: isUpdateLoading }] =
    useEditCardStockOrderMutation()

  const handleCardOrderChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setCardOrder((prev) => ({ ...prev, [name]: value }))

  const handleAddOrEditCardStockOrder = (event: any) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      let cardStockOrder = {
        adultQuantity: Number(cardOrder?.adultQuantity ?? null),
        childQuantity: Number(cardOrder?.childQuantity ?? null),
        deposit: deposit,
        type: cardOrder?.type,
        orderedAt: orderDate ? format(orderDate, "yyyy-MM-dd HH:mm:ss") : null,
        payment: {
          type: paiementType,
          chequeNumber: chequeNumber,
        } as Paiement,
      } as Partial<CardStockOrder>
      if (modalProps.isUpdate) {
        console.log(cardStockOrder)
        editCardStockOrder({
          id: modalProps.stockOrderId,
          body: cardStockOrder,
        })
          .unwrap()
          .then(() => modalProps.handleClose())
          .catch((error: ResponseApiException) => {
            console.error(error.data)
            if (error.data?.type === ResponseApiType.FormError) {
              setMsgError("Veuillez vérifier les données")
              setErrors(error.data?.detail)
              setValidated(true)
            } else if (error.data?.type === ResponseApiType.CustomError) {
              setMsgError(error.data?.detail)
            } else {
              setMsgError("Une erreur interne s'est produite.")
            }
          })
      } else {
        addCardStockOrder(cardStockOrder)
          .unwrap()
          .then(() => modalProps.handleClose())
          .catch((error: any) => {
            if (error.data?.type === ResponseApiType.FormError) {
              setMsgError("Veuillez vérifier les données")
              setErrors(error.data?.detail)
              setValidated(true)
            } else if (error.data?.type === ResponseApiType.CustomError) {
              setMsgError(error.data?.detail)
            } else {
              setMsgError("Une erreur interne s'est produite.")
            }
          })
      }
    }
  }

  const handlePaiement = (
    event: React.MouseEvent<HTMLElement>,
    newPaiement: string | null
  ) => {
    setPaiemenType(newPaiement)
    if (newPaiement === PaiementType.Cheque) {
      setIsChequePaiement(true)
    } else {
      setIsChequePaiement(false)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (modalProps.isUpdate) {
        setOrderPriceInfoLoading(true)
        API.get(`admin/card-stock-order/${modalProps.stockOrderId}/price`, {
          params: {
            type: cardOrder?.type,
            adult: +(cardOrder?.adultQuantity ?? 0),
            child: +(cardOrder?.childQuantity ?? 0),
          },
        })
          .then((response) => {
            setTotal(response.data)
            setOrderPriceInfoSuccess(true)
            setOrderPriceInfoError(null)
          })
          .catch((error) => {
            setOrderPriceInfoError(error.response.data)
            setOrderPriceInfoSuccess(false)
          })
          .finally(() => setOrderPriceInfoLoading(false))
      } else {
        setOrderPriceInfoLoading(true)
        API.get(`admin/card-stock-order/price`, {
          params: {
            type: cardOrder?.type,
            adult: +(cardOrder?.adultQuantity ?? 0),
            child: +(cardOrder?.childQuantity ?? 0),
            orderedAt: orderDate
              ? format(orderDate, "yyyy-MM-dd HH:mm:ss")
              : null,
          },
        })
          .then((response) => {
            setTotal(response.data)
            setOrderPriceInfoSuccess(true)
            setOrderPriceInfoError(null)
          })
          .catch((error) => {
            setOrderPriceInfoError(error.response.data)
            setOrderPriceInfoSuccess(false)
          })
          .finally(() => setOrderPriceInfoLoading(false))
      }
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [
    cardOrder?.adultQuantity,
    cardOrder?.childQuantity,
    cardOrder?.type,
    stockOrder,
    orderDate,
  ])

  //Init order if Edit
  useEffect(() => {
    if (modalProps.show) {
      if (modalProps.isUpdate) {
        setSkip(false)
        setOrderPriceInfoError(null)
        if (isSuccess) {
          setCardOrder({
            type: stockOrder?.type,
            adultQuantity: stockOrder?.adultQuantity,
            childQuantity: stockOrder?.childQuantity,
            orderedAt: stockOrder?.orderedAt,
            id: modalProps.stockOrderId,
            payment: stockOrder?.payment,
          })
          setDeposit(stockOrder?.deposit ?? 0)
          setIsChequePaiement(stockOrder?.payment?.type === PaiementType.Cheque)
          setTotal(stockOrder?.payment?.amount ?? null)
          setChequeNumber(stockOrder?.payment?.chequeNumber ?? "")
          setOrderDate(
            stockOrder?.orderedAt ? new Date(stockOrder?.orderedAt) : null
          )

          setPaiemenType(stockOrder?.payment?.type ?? null)
        }
      } else {
        setOrderPriceInfoError(null)
        setValidated(false)
        setMsgError("")
        setErrors({})
        setTotal(0)
        setIsChequePaiement(true)
        setChequeNumber("")
        setCardOrder((prev) => ({
          ...prev,
          type: StockOrderType.Pool,
          adultQuantity: undefined,
          childQuantity: undefined,
        }))
        setOrderDate(new Date())
        setPaiemenType(PaiementType.Cheque)
        setSkip(true)
      }
    } else {
      setSkip(true)
    }
  }, [modalProps.show, stockOrder, modalProps.isUpdate, isSuccess])

  const handleDepositChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement> | any) => {
    value = parseFloat(value)
    setDeposit(value)
  }

  useEffect(() => {
    let result = (total ?? 0) + (deposit ?? 0)
    setTotalWithDeposit(result)
  }, [deposit, total])

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {modalProps.isUpdate
              ? "Modifier une commande"
              : "Ajouter une commande"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          {modalProps.isUpdate && (
            <div className="mb-4">
              <FontAwesomeIcon icon={faWarning} color="darkred" /> &nbsp; Toute
              modification de la commande entrainera la mise à jour du budget
              CSE et du stock des cartes.
              <br />
              Les tarifs appliqués dépendent de la date de la commande indiquée.{" "}
            </div>
          )}
          <Form
            noValidate
            validated={validated}
            onSubmit={handleAddOrEditCardStockOrder}
          >
            <Form.Group className="mb-3">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={frLocale}
              >
                <DatePicker
                  label="Date de la commande"
                  value={orderDate}
                  onChange={(newValue) => {
                    setOrderDate(newValue)
                  }}
                  className="datePicker"
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {errors && errors.orderDate && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors &&
                    errors.orderDate?.map((error: any, index: number) => (
                      <div key={index}>{error}</div>
                    ))}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <div className=" mb-3 d-flex flex-column gap-1">
                <Span>
                  Type de commande : *{" "}
                  {errors && errors.type && "Ce champ est requis"}
                </Span>
                <ToggleButtonGroup
                  value={cardOrder?.type}
                  exclusive
                  aria-required
                  disabled={modalProps.isUpdate}
                  onChange={(
                    event: React.MouseEvent<HTMLElement>,
                    newType: string | null
                  ) => {
                    setCardOrder((prev: any) => ({ ...prev, type: newType }))
                  }}
                  aria-label="type de commande"
                >
                  <ToggleButton
                    value={StockOrderType.Pool}
                    aria-label="Piscine"
                  >
                    {getOrderTypeLabel(StockOrderType.Pool)}
                  </ToggleButton>
                  <ToggleButton
                    value={StockOrderType.Movie}
                    aria-label="Cinéma"
                  >
                    {getOrderTypeLabel(StockOrderType.Movie)}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Form.Group>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formAdultQte">
                <FloatingLabel
                  controlId="adultQuantity"
                  label="Nombre de places adultes"
                >
                  <Form.Control
                    placeholder="Nombre de places adultes"
                    aria-label="Nombre de places adultes"
                    name="adultQuantity"
                    required
                    type="number"
                    step={1}
                    min={0}
                    value={cardOrder?.adultQuantity ?? ""}
                    onChange={handleCardOrderChange}
                    isInvalid={validated && errors?.adultQuantity}
                  />
                  {errors && errors.adultQuantity ? (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {errors &&
                        errors.adultQuantity?.map(
                          (error: any, index: number) => (
                            <div key={index}>{error}</div>
                          )
                        )}
                    </Form.Control.Feedback>
                  ) : (
                    <Form.Control.Feedback type="invalid">
                      Veuillez entrer des chiffres entiers
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formChildQte">
                <FloatingLabel
                  controlId="childQuantity"
                  label="Nombre de places enfants"
                >
                  <Form.Control
                    placeholder="Nombre de places enfants"
                    aria-label="Nombre de places enfants"
                    name="childQuantity"
                    disabled={cardOrder?.type === StockOrderType.Pool}
                    required={cardOrder?.type === StockOrderType.Movie}
                    type="number"
                    step={1}
                    min={
                      cardOrder?.type === StockOrderType.Movie ? 0 : undefined
                    }
                    value={cardOrder.childQuantity ?? ""}
                    onChange={handleCardOrderChange}
                    isInvalid={validated && errors?.childQuantity}
                  />
                  {errors && errors.childQuantity ? (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: "block" }}
                    >
                      {errors &&
                        errors.childQuantity?.map(
                          (error: any, index: number) => (
                            <div key={index}>{error}</div>
                          )
                        )}
                    </Form.Control.Feedback>
                  ) : (
                    <Form.Control.Feedback type="invalid">
                      Veuillez entrer des chiffres entiers
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="mb-3 total">
              <Form.Group>
                <Span className="d-flex flex-wrap">
                  Montant :{" "}
                  {orderPriceInfoLoading ? (
                    <Loader />
                  ) : orderPriceInfoSuccess && total ? (
                    formatPrice(total)
                  ) : (
                    ""
                  )}
                </Span>
                {orderPriceInfoError && (
                  <div className="text-danger">
                    Une erreur s'est produite lors du calcul du montant :{" "}
                    {orderPriceInfoError.detail}
                  </div>
                )}
              </Form.Group>
            </Row>
            {cardOrder?.type === StockOrderType.Pool && (
              <>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="deposit">
                    <FloatingLabel controlId="deposit" label="Caution (€)">
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        name="deposit"
                        onChange={handleDepositChange}
                        isInvalid={validated && errors?.deposit}
                        value={deposit ?? 0}
                      />
                      <Form.Text className="text-muted">
                        Merci de préciser à combien s'élève le montant des
                        cautions qui vous a été facturé
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {errors?.deposit}
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                </Row>

                <Row className="mb-3 total">
                  <Form.Group>
                    <Span className="d-flex flex-wrap">
                      Montant total :{" "}
                      {orderPriceInfoLoading ? (
                        <Loader />
                      ) : orderPriceInfoSuccess && total ? (
                        formatPrice(totalWithDeposit ?? 0)
                      ) : (
                        ""
                      )}
                    </Span>
                    {orderPriceInfoError && (
                      <div className="text-danger">
                        Une erreur s'est produite lors du calcul du montant
                        total : {orderPriceInfoError.detail}
                      </div>
                    )}
                  </Form.Group>
                </Row>
              </>
            )}
            <Form.Group className="mb-4">
              <div className=" mb-3 d-flex flex-column gap-1">
                <Span>
                  Type de paiement : *{" "}
                  {errors && errors.adminPayment && "Ce champ est requis"}
                </Span>
                <ToggleButtonGroup
                  value={paiementType}
                  exclusive
                  aria-required
                  onChange={handlePaiement}
                  aria-label="type de paiement"
                >
                  <ToggleButton
                    value={PaiementType.Cash}
                    aria-label="en espèces"
                  >
                    {getPaiementTypeLabel(PaiementType.Cash)}
                  </ToggleButton>
                  <ToggleButton
                    value={PaiementType.Cheque}
                    aria-label="par chèque"
                  >
                    {getPaiementTypeLabel(PaiementType.Cheque)}
                  </ToggleButton>
                </ToggleButtonGroup>
                {isChequePaiement && (
                  <Form.Control
                    className="mt-1"
                    placeholder="Numéro de chèque"
                    value={chequeNumber ?? ""}
                    onChange={(e) => setChequeNumber(e.target.value)}
                  />
                )}
              </div>
            </Form.Group>

            <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
            <div className="d-flex justify-content-end gap-3 flex-wrap">
              <ButtonPrimary
                label="sauvegarder"
                type="submit"
                disabled={!total || total <= 0}
                loading={modalProps.isUpdate ? isUpdateLoading : isPostLoading}
              />
              <ButtonSecondary
                onClick={modalProps.handleClose}
                label="Annuler"
                type="button"
              />
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

export default CardStockOrderModal
