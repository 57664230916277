import styled from "styled-components"
import InternError from "../../../components/InternError"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../../models/responseApiType"
import { Loader } from "../../../utils/Atoms"
import colors from "../../../utils/style/colors"
import { formatPrice, formatStrToDate } from "../../../utils/utils"
import Toastify from "toastify-js"
import BankDeposit from "../../../models/bankDeposit"
import {
  useDeleteBankDepositMutation,
  useGetBankDepositQuery,
} from "../../../features/bankDepositSlice"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Col, Container, Row } from "react-bootstrap"
import { Breadcrumbs, Button, Typography } from "@mui/material"
import { ParentOrder } from "../../../models/orderAdmin"
import { getPaiementTypeLabel, PaiementType } from "../../../models/paiement"
import DataTable from "react-data-table-component"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEuroSign,
  faMoneyCheck,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons"
import { API } from "../../../utils/API"
import ButtonPrimary from "../../../components/ButtonPrimary"
import ValidateModal from "../../../components/ValidateModal"
import { useState } from "react"

const LinkStyled = styled(Link)`
  text-decoration: none;
  color: #929292;
  &:hover {
    text-decoration: underline;
    color: ${colors.violet};
  }
`

const StyledChequeNumber = styled.span`
  font-size: 0.8rem;
  color: ${colors.violet};
  font-weight: bold;
`

const StyledDiv = styled(Container)`
  padding: 2rem 0.5rem 2rem 2.6rem;

  @media all and (min-width: 768px) {
    padding: 2rem 0.5rem 2rem 3.5rem;
  }

  h1 {
    font-size: 1.6rem;
    color: ${colors.violet};
    text-align: center;
    margin-bottom: 2rem;
  }
`

const CardDiv = styled.div`
  @media all and (min-width: 992px) {
    padding: 0 5rem;
  }

  @media all and (min-width: 1200px) {
    padding: 0 6rem;
  }
`

const StyledCol = styled(Col)`
  font-weight: bold;
  font-size: 1rem;
  color: ${colors.darkViolet};
`

function BankDepositItem() {
  let { id } = useParams()
  const [showConfirm, setShowConfirm] = useState(false)
  let navigate = useNavigate()

  const {
    data: bankDeposit,
    isLoading,
    isSuccess,
    isError,
    error,
  }: {
    data: BankDeposit
    isLoading: boolean
    isSuccess: boolean
    isError: boolean
    error: ResponseApiException
  } = useGetBankDepositQuery(id)

  const [deleteBankDeposit, { isLoading: isDeleteLoading }] =
    useDeleteBankDepositMutation()

  const columns = [
    {
      name: "Date",
      selector: (row: ParentOrder) => row.orderDate,
      sortable: true,
      format: (row: ParentOrder) =>
        formatStrToDate(row.orderDate, "dd/MM/yyyy"),
    },
    {
      name: "Numéro",
      selector: (row: ParentOrder) => row.number,
    },
    {
      name: "Salarié",
      sortable: true,
      selector: (row: ParentOrder) => row.owner,
      format: (row: ParentOrder) => {
        if (!row.owner.firstName && !row.owner.lastname) return row.owner.email
        else return `${row.owner.firstName} ${row.owner.lastname}`
      },
    },
    {
      name: "Libellé",
      sortable: true,
      selector: (row: ParentOrder) => row.title,
      wrap: true,
    },
    {
      name: "Montant",
      selector: (row: ParentOrder) => row.amount,
      sortable: true,
      format: (row: ParentOrder) => formatPrice(row.amount),
    },
    {
      name: "Paiement",
      center: true,
      sortable: true,
      selector: (row: ParentOrder) => row.payment?.type,
      cell: (row: ParentOrder) =>
        row.payment && (
          <div className="d-flex flex-column justify-content-center text-center">
            <span>{getPaiementTypeLabel(row.payment.type)}</span>
            {row.payment.type === PaiementType.Cheque &&
              row.payment.chequeNumber && (
                <StyledChequeNumber>
                  {row.payment.chequeNumber}
                </StyledChequeNumber>
              )}
          </div>
        ),
    },
  ] as any

  const paginationComponentOptions = {
    rowsPerPageText: "Éléments par page",
    rangeSeparatorText: "de",
  }

  let content
  if (isLoading) {
    content = <Loader />
  } else if (isError) {
    if (error.data?.type === ResponseApiType.CustomError) {
      content = error.data?.detail
    } else {
      content = <InternError />
    }
  } else if (isSuccess) {
    console.log(bankDeposit)
    content = (
      <CardDiv className="gap-2 justify-content-center w-100">
        <Row className="justify-content-start my-2 mb-3">
          <ButtonPrimary
            style={{
              padding: "0.5rem 0.8rem",
              borderRadius: "8px",
              alignSelf: "center",
              background: colors.violet,
            }}
            label="Exporter"
            onClick={() => handleExport()}
          />
        </Row>
        <Row className="justify-content-start my-2 mb-3">
          <StyledCol>
            Date de remise en banque :{" "}
            {formatStrToDate(bankDeposit.depositedAt)}
          </StyledCol>
        </Row>
        <Row>
          <DataTable
            columns={columns}
            data={bankDeposit.orders ?? []}
            progressPending={isLoading}
            pagination
            responsive
            striped
            dense
            highlightOnHover
            paginationPerPage={20}
            noDataComponent="Aucune commande"
            paginationComponentOptions={paginationComponentOptions}
            progressComponent={<Loader></Loader>}
          ></DataTable>
        </Row>
        <Row className="mb-2">
          <StyledCol>
            <FontAwesomeIcon icon={faSackDollar} color="#239770" size="lg" />
            &nbsp; Versement en espèce : {formatPrice(bankDeposit.totalCash)}
          </StyledCol>
        </Row>
        <Row className="mb-3">
          <StyledCol>
            <FontAwesomeIcon icon={faMoneyCheck} color="#679cca" size="lg" />
            &nbsp; Remise de chèque {bankDeposit.chequeNumber}:{" "}
            {formatPrice(bankDeposit.totalCheque)}
          </StyledCol>
        </Row>
        <Row className="mb-2 text-start text-md-end">
          <StyledCol>
            <FontAwesomeIcon icon={faEuroSign} color="#dcac01" size="lg" />
            &nbsp; TOTAL :&nbsp;
            {formatPrice(
              (bankDeposit.totalCheque ?? 0) + (bankDeposit.totalCash ?? 0)
            )}
          </StyledCol>
        </Row>
      </CardDiv>
    )
  }
  const handleValidate = () => {
    //post paiement and ids => set to ordered status and add paiement to other orders
    deleteBankDeposit(id)
      .unwrap()
      .then(() => {
        Toastify({
          text: "La remise en banque a bien été supprimée",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        navigate("/admin/bank-deposit")
      })
      .catch((error: ResponseApiException) => {
        console.error(error)
      })
      .finally(setShowConfirm(false))
  }

  const handleExport = () => {
    API.get(`admin/bank-deposit/${id}/export`, {
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute(
          "download",
          `remise-en-banque_${formatStrToDate(
            bankDeposit.depositedAt,
            "dd-MM-yyyy"
          )}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        Toastify({
          text: "Une erreur s'est produite lors du téléchargement",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
      })
  }

  return (
    <StyledDiv fluid>
      <Breadcrumbs aria-label="breadcrumb" className="mb-4">
        <LinkStyled color="gray" to="/admin/bank-deposit">
          Remises en banque
        </LinkStyled>
        <Typography color="text.primary">Fiche d'une remise</Typography>
      </Breadcrumbs>
      <h1>Remise en banque du {formatStrToDate(bankDeposit?.depositedAt)}</h1>
      <div className="d-flex justify-content-center">{content}</div>
      <div className="mx-2 mt-3 d-flex justify-content-end">
        <Button
          color="error"
          variant="outlined"
          onClick={() => setShowConfirm(true)}
        >
          Supprimer
        </Button>
      </div>
      <ValidateModal
        show={showConfirm}
        handleClose={() => setShowConfirm(false)}
        modalMessage="Êtes-vous sûr de vouloir supprimer cette remise en banque ? Toutes les commandes seront remises au statut payé et le budget sera mis à jour."
        modalTitle="Suppression remise en banque"
        handleConfirm={handleValidate}
        loading={isDeleteLoading}
      />
    </StyledDiv>
  )
}

export default BankDepositItem
