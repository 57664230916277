import { FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import ButtonPrimary from "../ButtonPrimary"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import ButtonSecondary from "../ButtonSecondary"
import { Fragment, useEffect, useMemo, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Toastify from "toastify-js"
import DeleteIcon from "@mui/icons-material/Delete"
import {
  faBank,
  faBox,
  faEuroSign,
  faInfoCircle,
  faMoneyCheck,
  faPlusCircle,
  faQuestionCircle,
  faSackDollar,
  faWarning,
} from "@fortawesome/free-solid-svg-icons"
import frLocale from "date-fns/locale/fr"
import { useGetOrdersByStatusQuery } from "../../features/orderSlice"
import DataTable from "react-data-table-component"
import { Loader } from "../../utils/Atoms"
import { formatPrice, formatStrToDate } from "../../utils/utils"
import { ParentOrder } from "../../models/orderAdmin"
import {
  getOrderStatusLabel,
  getStatusColor,
  OrderStatusType,
} from "../../models/order"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import ValidateModal from "../ValidateModal"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import {
  Autocomplete,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material"
import { format } from "date-fns"
import { getPaiementTypeLabel, PaiementType } from "../../models/paiement"
import { useGetUsersQuery } from "../../features/userSlice"
import PersonalInfos from "../../models/personalInfos"
import { usePostBankDepositMutation } from "../../features/bankDepositSlice"

interface ModalProps {
  show: boolean
  handleClose: () => void
}

const StyledModal = styled(Modal)`
  font-size: 0.9rem;
  h2 {
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .prices span,
  .total span {
    font-weight: bold;
  }

  .modal-header {
    background-color: ${colors.violet};
    color: white;
  }
`

const StyledChequeNumber = styled.span`
  font-size: 0.8rem;
  color: ${colors.violet};
  font-weight: bold;
`

const StyledSpan = styled.span<{ status: OrderStatusType }>`
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  align-self: center;
  text-align: center;
  white-space: nowrap;
  background-color: ${(prop) =>
    prop.status ? getStatusColor(prop.status) : "white"};
  color: white;
  margin-left: 0.5rem;
`

const Span = styled.span`
  font-weight: bold;
  color: ${colors.darkViolet};
`

function BankDepositModal(modalProps: ModalProps) {
  const init = {
    paidAt: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
  } as Partial<ParentOrder>
  const [validated, setValidated] = useState(false)
  const [validatedManual, setValidatedManual] = useState(false)
  const [showManualForm, setShowManualForm] = useState(false)
  const [errors, setErrors] = useState({} as any)
  const [msgError, setMsgError] = useState<string>()
  const [perPage, setPerPage] = useState(30)
  const [selectedOrders, setSelectedOrders] = useState([])
  const [showConfirm, setShowConfirm] = useState(false)
  const [depositedAt, setDepositedAt] = useState<Date | null>(new Date())
  const [total, setTotal] = useState<number>(0)
  const [totalCheque, setTotalCheque] = useState<number>(0)
  const [totalCash, setTotalCash] = useState<number>(0)
  const [chequeNumber, setChequeNumber] = useState<string>()
  const [manualOrders, setManualOrders] = useState<ParentOrder[]>([])
  const [postManualOrder, setPostManualOrder] = useState<
    ParentOrder | Partial<ParentOrder>
  >(init)

  const [to, setTo] = useState<Date | null>(new Date())

  const handlePerRowsChange = (newPerPage: number) => {
    setPerPage(newPerPage)
  }

  useEffect(() => {
    if (modalProps.show) {
      setValidated(false)
      setMsgError("")
      setErrors({})
      setPostManualOrder({ ...init, id: Date.now() })
      setManualOrders([])
      setDepositedAt(new Date())
    }
  }, [modalProps.show])

  const paginationComponentOptions = {
    rowsPerPageText: "Commandes par page",
    rangeSeparatorText: "de",
  }

  const columns = [
    {
      name: "Date commande",
      selector: (row: ParentOrder) => row.orderDate,
      sortable: true,
      format: (row: ParentOrder) =>
        formatStrToDate(row.orderDate, "dd/MM/yyyy"),
    },
    {
      name: "Numéro",
      selector: (row: ParentOrder) => row.number,
    },
    {
      name: "Salarié",
      sortable: true,
      selector: (row: ParentOrder) => row.owner,
      format: (row: ParentOrder) => {
        if (!row.owner.firstName && !row.owner.lastname) return row.owner.email
        else return `${row.owner.firstName} ${row.owner.lastname}`
      },
    },
    {
      name: "Libellé",
      sortable: true,
      selector: (row: ParentOrder) => row.title,
    },
    {
      name: "Montant",
      selector: (row: ParentOrder) => row.amount,
      format: (row: ParentOrder) => formatPrice(row.amount),
    },
    {
      name: "Paiement",
      center: true,
      selector: (row: ParentOrder) => row.payment,
      cell: (row: ParentOrder) =>
        row.payment && (
          <div className="d-flex flex-column justify-content-center text-center">
            <span>{getPaiementTypeLabel(row.payment.type)}</span>
            {row.payment.type === PaiementType.Cheque &&
              row.payment.chequeNumber && (
                <StyledChequeNumber>
                  {row.payment.chequeNumber}
                </StyledChequeNumber>
              )}
          </div>
        ),
    },
    {
      name: "Statut",
      center: true,
      grow: 1,
      selector: (row: ParentOrder) => row.status,
      cell: (row: ParentOrder) => (
        <StyledSpan status={row.status}>
          {getOrderStatusLabel(row.status)}
        </StyledSpan>
      ),
    },
  ] as any

  const manualColumns = [
    {
      name: "Titre",
      selector: (row: ParentOrder) => row.title,
    },
    {
      name: "Salarié",
      selector: (row: ParentOrder) => row.owner,
      format: (row: ParentOrder) => {
        return row.owner ? `${row.owner.firstName} ${row.owner.lastname}` : ""
      },
    },
    {
      name: "Paiement",
      selector: (row: ParentOrder) => row.payment,
      cell: (row: ParentOrder) =>
        row.payment && <div>{getPaiementTypeLabel(row.payment.type)}</div>,
    },
    {
      name: "Montant",
      selector: (row: ParentOrder) => row.amount,
      format: (row: ParentOrder) => formatPrice(row.amount),
    },
    {
      name: "Date paiement",
      selector: (row: ParentOrder) => row.paidAt,
      format: (row: ParentOrder) => formatStrToDate(row.paidAt, "dd/MM/yyyy"),
    },
    {
      name: "Actions",
      cell: (row: ParentOrder) => (
        <div>
          <Tooltip title="Supprimer">
            <IconButton
              onClick={() => handleRemoveManualOrder(row.id)}
              aria-label="supprimer"
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      wrap: true,
      minWidth: "8rem",
    },
  ] as any

  const {
    data: orders = [],
    isLoading,
    isSuccess,
    isError,
  } = useGetOrdersByStatusQuery(OrderStatusType.Payed, {
    skip: !modalProps.show,
  })

  const {
    data: users = [],
    isLoading: isUsersLoading,
    isSuccess: isUsersSuccess,
    isError: isUsersError,
  } = useGetUsersQuery(
    {},
    {
      skip: !showManualForm,
    }
  )

  const handleRemoveManualOrder = (id: number) => {
    const newManualOrders = manualOrders.filter(
      (item: ParentOrder) => item.id !== id
    )
    setManualOrders(newManualOrders)
  }

  const [postBankDeposit, { isLoading: isPostLoading }] =
    usePostBankDepositMutation()

  useEffect(() => {
    let manualCheque = 0
    let manuelCash = 0
    if (manualOrders && manualOrders.length > 0) {
      manualCheque = manualOrders
        .filter(
          (item: ParentOrder) =>
            item.payment && item.payment.type === PaiementType.Cheque
        )
        .reduce((sum: number, current: ParentOrder) => sum + +current.amount, 0)

      manuelCash = manualOrders
        .filter(
          (item: ParentOrder) =>
            item.payment && item.payment.type === PaiementType.Cash
        )
        .reduce((sum: number, current: ParentOrder) => sum + +current.amount, 0)
    }

    let amountTotal = selectedOrders.reduce(
      (sum: number, current: ParentOrder) => sum + +current.amount,
      0
    )
    setTotal(amountTotal + manualCheque + manuelCash)

    let amountChequeTotal = selectedOrders
      .filter(
        (item: ParentOrder) =>
          item.payment && item.payment.type === PaiementType.Cheque
      )
      .reduce((sum: number, current: ParentOrder) => sum + +current.amount, 0)

    setTotalCheque(amountChequeTotal + manualCheque)

    let amountCashTotal = selectedOrders
      .filter(
        (item: ParentOrder) =>
          item.payment && item.payment.type === PaiementType.Cash
      )
      .reduce((sum: number, current: ParentOrder) => sum + +current.amount, 0)
    setTotalCash(amountCashTotal + manuelCash)
  }, [selectedOrders, manualOrders])

  const handleRowSelected = ({ selectedRows }: any) => {
    setSelectedOrders(selectedRows)
  }

  const handleValidate = () => {
    let postAdmin = {
      ordersIds: selectedOrders.map((item: ParentOrder) => item.id),
      depositedAt: depositedAt
        ? format(depositedAt, "yyyy-MM-dd HH:mm:ss")
        : null,
      chequeNumber: chequeNumber,
      manualOrders: manualOrders,
    }

    console.log(postAdmin)
    //post paiement and ids => set to ordered status and add paiement to other orders
    postBankDeposit(postAdmin)
      .unwrap()
      .then(() => {
        Toastify({
          text: "La remise en banque a bien été créée",
          duration: 8000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          stopOnFocus: true, // Prevents dismissing of toast on hover
          style: {
            background: "linear-gradient(to right, #29aac4, #bd79e7)",
          },
          onClick: function () {}, // Callback after click
        }).showToast()
        modalProps.handleClose()
      })
      .catch((error: ResponseApiException) => {
        console.error(error)
        if (error.data?.type === ResponseApiType.FormError) {
          setMsgError("Veuillez vérifier les données")
          setErrors(error.data?.detail)
          setValidated(true)
        } else if (error.data?.type === ResponseApiType.CustomError) {
          setMsgError(error.data?.detail)
        } else {
          setMsgError("Une erreur interne s'est produite.")
        }
      })
      .finally(setShowConfirm(false))
  }

  const handleNewManualChange = ({ target: { name, value } }: any) => {
    setPostManualOrder((prev: any) => ({ ...prev, [name]: value.trim() }))
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    } else {
      setShowConfirm(true)
    }
  }

  const handleSubmitManual = () => {
    if (
      !postManualOrder ||
      !postManualOrder.title ||
      !postManualOrder.paidAt ||
      !postManualOrder.amount ||
      !postManualOrder.payment
    ) {
      //check required data
      setValidatedManual(true)
    } else {
      //push postmanual to manualorders and set show false
      if (postManualOrder)
        setManualOrders((prev: any) => [...prev, postManualOrder])
      setShowManualForm(false)
    }
  }

  useEffect(() => {
    if (showManualForm) {
      setValidatedManual(false)
      setPostManualOrder({ ...init, id: Date.now() })
    }
  }, [showManualForm])

  const employeesOptions = useMemo(() => {
    let datas = users.map((user: PersonalInfos) => ({
      label: `${user.lastname} ${user.firstName}`,
      id: user.id,
      firstName: user.firstName,
      lastname: user.lastname,
    }))

    return datas
  }, [users])

  useEffect(() => {
    let newDate: any
    try {
      newDate = to ? format(to, "yyyy-MM-dd HH:mm:ss") : null
    } catch (error: any) {
      newDate = null
    }
    setPostManualOrder((prev: any) => ({
      ...prev,
      paidAt: newDate,
    }))
  }, [to])

  return (
    <>
      <StyledModal
        show={modalProps.show}
        onHide={modalProps.handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-width-refund p-0"
        centered
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={frLocale}
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title className="d-flex gap-2 pt-1 align-items-center align-items-sm-start">
              <FontAwesomeIcon icon={faBank} color="white" />
              <h2>Ajout d'une remise en banque</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3 ps-2">
                Veuillez sélectionner les commandes correspondant à cette remise
                en banque :
              </Row>
              <div className="mb-3">
                <FontAwesomeIcon icon={faInfoCircle} color={colors.violet} />{" "}
                <span className="fst-italic" style={{ color: "grey" }}>
                  Une fois que vous aurez validé, toutes les commandes
                  sélectionnées passeront automatiquement au statut encaissée et
                  le budget CSE sera mis à jour
                </span>
              </div>
              <Row>
                {isSuccess && (
                  <DataTable
                    columns={columns}
                    data={orders}
                    progressPending={isLoading}
                    pagination
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationPerPage={perPage}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    responsive
                    striped
                    selectableRows
                    onSelectedRowsChange={handleRowSelected}
                    highlightOnHover
                    noDataComponent="Aucune commande au statut Payée"
                    progressComponent={<Loader></Loader>}
                    paginationComponentOptions={paginationComponentOptions}
                  ></DataTable>
                )}
              </Row>
              <Row className="mb-3 ps-2">
                Vous avez un cas particulier dans cette remise en banque qui ne
                correspond pas à une commande de piscine, cinéma ou autre ? Vous
                pouvez l'ajouter manuellement ci-dessous.
              </Row>
              <Row className="mb-3 ps-2">
                <ButtonPrimary
                  size="small"
                  label="ajouter"
                  icon={faPlusCircle}
                  onClick={() => setShowManualForm(true)}
                />
              </Row>
              {showManualForm && (
                <Row className="mb-3 ps-2">
                  <Form.Group className="mb-4 px-2" controlId="formTitle">
                    <FloatingLabel controlId="title" label="Titre *">
                      <Form.Control
                        type="text"
                        name="title"
                        onChange={handleNewManualChange}
                        isInvalid={validatedManual && !postManualOrder?.title}
                        style={{ maxWidth: "30rem" }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Le titre est requis
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group className="mb-4 px-2" controlId="formEmployee">
                    <Autocomplete
                      disablePortal
                      id="employee"
                      loading={isUsersLoading}
                      noOptionsText="Aucun correspondant"
                      options={employeesOptions}
                      sx={{ width: 300 }}
                      onChange={(event, value: any) =>
                        setPostManualOrder((prev: any) => ({
                          ...prev,
                          owner: {
                            ...prev?.owner,
                            id: value.id,
                            firstName: value.firstName,
                            lastname: value.lastname,
                          },
                        }))
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Salarié" />
                      )}
                    />{" "}
                  </Form.Group>
                  <Form.Group className="mb-4 px-2" controlId="formAmount">
                    <FloatingLabel controlId="amount" label="Montant (€) *">
                      <Form.Control
                        type="number"
                        step={0.01}
                        min={0}
                        name="amount"
                        onChange={handleNewManualChange}
                        isInvalid={validatedManual && !postManualOrder?.amount}
                        style={{ maxWidth: "30rem" }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Le montant est requis
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group
                    className="mb-4 px-2"
                    controlId="formChequeNumberM"
                  >
                    <div className="mb-3 d-flex flex-column gap-1">
                      <Span>
                        Type de paiement : *{" "}
                        {errors && errors.adminPayment && "Ce champ est requis"}
                      </Span>
                      <ToggleButtonGroup
                        exclusive
                        aria-required
                        value={postManualOrder?.payment?.type}
                        onChange={(event, value) =>
                          setPostManualOrder((prev: any) => ({
                            ...prev,
                            payment: { ...prev?.payment, type: value },
                          }))
                        }
                        aria-label="type de paiement"
                      >
                        <ToggleButton
                          value={PaiementType.Cash}
                          aria-label="en espèces"
                        >
                          {getPaiementTypeLabel(PaiementType.Cash)}
                        </ToggleButton>
                        <ToggleButton
                          value={PaiementType.Cheque}
                          aria-label="par chèque"
                        >
                          {getPaiementTypeLabel(PaiementType.Cheque)}
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {validatedManual && !postManualOrder?.payment?.type && (
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: "block" }}
                        >
                          Le type de paiement est requis
                        </Form.Control.Feedback>
                      )}
                      {postManualOrder?.payment?.type ===
                        PaiementType.Cheque && (
                        <Fragment>
                          <Form.Control
                            className="mt-1"
                            placeholder="Numéro de chèque"
                            name="chequeNumber"
                            isInvalid={
                              validatedManual &&
                              postManualOrder?.payment.type ===
                                PaiementType.Cheque &&
                              !postManualOrder?.payment.chequeNumber
                            }
                            style={{ maxWidth: "30rem" }}
                            onChange={(e) =>
                              setPostManualOrder((prev: any) => ({
                                ...prev,
                                payment: {
                                  ...prev?.payment,
                                  chequeNumber: e.target.value,
                                },
                              }))
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Le numéro de chèque est requis
                          </Form.Control.Feedback>
                        </Fragment>
                      )}
                    </div>
                  </Form.Group>
                  <div className="mb-3 px-2">
                    <DatePicker
                      label="Date de paiement *"
                      value={to}
                      onChange={(newValue) => {
                        setTo(newValue)
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "jj/mm/aaaa",
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="d-flex justify-content-end gap-3 flex-wrap">
                    <ButtonPrimary
                      label="Valider"
                      type="button"
                      onClick={() => handleSubmitManual()}
                    />
                    <ButtonSecondary
                      onClick={() => setShowManualForm(false)}
                      label="Annuler"
                      type="button"
                    />
                  </div>
                </Row>
              )}
              {manualOrders && manualOrders.length > 0 && (
                <Row className="mb-3 ps-2">
                  <DataTable
                    data={manualOrders}
                    columns={manualColumns}
                    striped
                  />
                </Row>
              )}
              <Row className="mb-3 ps-2">
                <div
                  className="mt-2 ps-2 d-flex flex-wrap gap-1 gap-md-2 align-items-baseline"
                  style={{ fontWeight: "bold" }}
                >
                  <FontAwesomeIcon
                    icon={faMoneyCheck}
                    color="#679cca"
                    size="lg"
                  />
                  Montant total par chèque :{" "}
                  {totalCheque ? (
                    formatPrice(totalCheque)
                  ) : (
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      color={colors.violet}
                    />
                  )}
                </div>
                <div
                  className="mt-3 ps-2 d-flex flex-wrap gap-1 gap-md-2 align-items-baseline"
                  style={{ fontWeight: "bold" }}
                >
                  <FontAwesomeIcon
                    icon={faSackDollar}
                    color="#239770"
                    size="lg"
                  />
                  <span>Montant total en espèces :</span>
                  {totalCash ? (
                    formatPrice(totalCash)
                  ) : (
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      color={colors.violet}
                    />
                  )}
                </div>
                <div
                  className="mt-3 mb-2 ps-2 d-flex flex-wrap gap-1 gap-md-2"
                  style={{ fontWeight: "bold", fontSize: "1rem" }}
                >
                  <FontAwesomeIcon
                    icon={faEuroSign}
                    color="#dcac01"
                    size="lg"
                  />
                  Montant total :{" "}
                  {total ? (
                    formatPrice(total)
                  ) : (
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      color={colors.violet}
                    />
                  )}
                </div>
                <div className="mb-3 ps-2" style={{ color: "darkred" }}>
                  <FontAwesomeIcon icon={faWarning} color="darkred" size="lg" />
                  &nbsp; Veillez bien à vérifier que le montant total correspond
                  à la remise en banque réalisée, aucune modification possible
                  après validation.
                </div>
              </Row>
              {totalCheque > 0 && (
                <Form.Group className="mb-4 px-2" controlId="formChequeNumber">
                  <FloatingLabel
                    controlId="chequeNumber"
                    label="Numéro remise de chèque *"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Numéro remise de chèque"
                      required={totalCheque > 0}
                      name="chequeNumber"
                      onChange={(e) => setChequeNumber(e.target.value)}
                      isInvalid={validated && errors.title}
                      style={{ maxWidth: "25rem" }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Le numéro de remise de chèque est requis
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              )}
              <div className="mb-3 px-2">
                <DatePicker
                  label="Date de la remise en banque"
                  value={depositedAt}
                  onChange={(newValue) => {
                    setDepositedAt(newValue)
                  }}
                  maxDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "jj/mm/aaaa",
                      }}
                    />
                  )}
                />
                {errors && errors.depositedAt && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {errors && errors.depositedAt}
                  </Form.Control.Feedback>
                )}
              </div>
              <Form.Group className="my-3 text-danger">{msgError}</Form.Group>
              <div className="d-flex justify-content-end gap-3 flex-wrap">
                <ButtonPrimary
                  label="Valider"
                  type="submit"
                  disabled={!total || total <= 0}
                  loading={isPostLoading}
                />
                <ButtonSecondary
                  onClick={modalProps.handleClose}
                  label="Annuler"
                  type="button"
                />
              </div>
              <ValidateModal
                show={showConfirm}
                handleClose={() => setShowConfirm(false)}
                modalMessage="Merci de bien vérifier les données avant de valider, celles-ci ne seront pas modifiables"
                modalTitle="Confirmation remise en banque"
                handleConfirm={handleValidate}
                loading={isPostLoading}
              />
            </Form>
          </Modal.Body>
        </LocalizationProvider>
      </StyledModal>
    </>
  )
}

export default BankDepositModal
