import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { Link } from "react-router-dom"
import styled from "styled-components"
import colors from "../../utils/style/colors"
import logo from "../../assets/logo-tac.png"
import { Divider, ListItemIcon, Menu, MenuItem } from "@mui/material"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { Info, Logout, Settings } from "@mui/icons-material"
import { useAuth } from "../../utils/hooks/useAuth"

const StyledLink = styled(Link)`
  color: white !important;
  font-size: 1.1rem !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  &:hover,
  &.active {
    color: ${colors.blue}!important;
  }
`
const StyledNav = styled(Navbar)`
  padding: ${(props) => (props.open ? "0.2rem 0rem 1.2rem 0rem" : "inherit")};
  background-color: ${(props) => (props.open ? colors.violet : "inherit")};
  button.navbar-toggler {
    padding: 0.5rem;
    border: 1px solid white;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    .navbar-toggler-icon {
      color: white;
    }
  }
`

const StyledButtonLink = styled(Button)`
  background-color: white !important;
  color: black !important;
  font-size: 1.1rem !important;
  border-radius: 30px !important;
  margin-left: 0.5rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  border-color: transparent;
  box-shadow: none;
  &:hover,
  &:active {
    background-color: ${colors.darkViolet}!important;
    color: white !important;
    border-color: ${colors.darkViolet}!important;
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
    border-color: ${colors.darkViolet}!important;
  }
`

function Header() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [expanded, setExpanded] = useState(false)
  const open = Boolean(anchorEl)
  const { logout } = useAuth()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <StyledNav
      collapseOnSelect
      expand="lg"
      className="position-absolute w-100 navbar-dark"
      open={expanded}
      onToggle={() => setExpanded(!expanded)}
    >
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src={logo}
            width="85"
            height="85"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav>
            <Nav.Link as={StyledLink} to="/">
              Accueil
            </Nav.Link>
            <Nav.Link as={StyledLink} to="/presentation">
              Présentation
            </Nav.Link>
            <Nav.Link as={StyledLink} to="/news">
              Actualités
            </Nav.Link>
            <Nav.Link as={StyledLink} to="/documents">
              Documents
            </Nav.Link>
            <Nav.Link as={StyledLink} to="/contact">
              Contact
            </Nav.Link>
            <StyledButtonLink
              onClick={handleClick}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              className="mt-2 mt-md-0"
            >
              Mon profil
            </StyledButtonLink>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              disableScrollLock
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Link
                  to="/commandes"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Mes commandes
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  to="/remboursements"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Mes remboursements
                </Link>
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <Info fontSize="small" />
                </ListItemIcon>
                <Link
                  to="/infos-personnelles"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Infos. personnelles
                </Link>
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                <Link
                  to="/parametres"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Paramètres
                </Link>
              </MenuItem>
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Déconnexion
              </MenuItem>
            </Menu>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNav>
  )
}

export default Header
