import { Form } from "react-bootstrap"
import styled from "styled-components"
import ButtonPrimary from "../../components/ButtonPrimary"
import bgImage from "../../assets/bus.jpg"
import { useState } from "react"
import { usePostResetPasswordMutation } from "../../features/userSlice"
import {
  ResponseApiException,
  ResponseApiType,
} from "../../models/responseApiType"
import { getDifferenceInMinutes } from "../../utils/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

const StyledDiv = styled.div`
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw;
  align-self: center;
  border-radius: 15px;
  box-shadow: grey 2px 2px 15px;
  @media (min-width: 576px) {
    padding: 2rem;
    width: 30rem;
  }
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6),
      rgba(83, 44, 110, 0.5)
    ),
    url(${bgImage});
  background-size: cover;
  background-position: center;
  min-height: 100vh;
`

function ResetPassword() {
  const [email, setEmail] = useState<string>()
  const [validated, setValidated] = useState(false)
  const [msg, setMsg] = useState("")

  const [postReset, { isLoading }] = usePostResetPasswordMutation()

  const canSubmit = email && !isLoading

  const handleSubmit = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      return
    } else {
      setValidated(true)
      //reset
      postReset({ email: email })
        .unwrap()
        .then((response: any) => {
          console.log(response.resetToken)
          let expiredAt = response.resetToken?.expiresAt
          setMsg(expiredAt)
        })
        .catch((error: ResponseApiException) => {
          console.error(error)
          if (error.data?.type === ResponseApiType.CustomError) {
            //setMsgError(error.data?.detail)
          } else {
            //setMsgError("Une erreur interne s'est produite.")
          }
        })
    }
  }

  return (
    <StyledContainer>
      <h1 className="text-center p-4 pb-5" style={{ color: "white" }}>
        CSE CTCM
      </h1>
      <StyledDiv>
        <h2 className="text-center pb-4 fs-4 violet">
          Réinitialisation du mot de passe
        </h2>
        <Form onSubmit={(e) => handleSubmit(e)} validated={validated}>
          {!msg && (
            <div className="mb-3">
              Veuillez indiquer votre adresse email et nous vous enverrons un
              lien pour réinitialiser votre mot de passe.
            </div>
          )}
          {msg && (
            <div className="mb-3 fst-italic">
              <FontAwesomeIcon icon={faCheckCircle} color="green" /> Si un
              compte correspond à cette adresse mail, alors un mail a été envoyé
              afin que vous puissiez réinitaliser votre mot de passe. Le lien
              expirera dans {getDifferenceInMinutes(msg)} minutes. Si vous
              n'avez pas reçu d'email merci de vérifier vos spams ou réessayer.
            </div>
          )}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              required
              type="email"
              placeholder="Entrez votre email"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              name="email"
            />
          </Form.Group>

          <div className="d-flex justify-content-center mt-4 w-100">
            <ButtonPrimary
              label="Valider"
              type="submit"
              loading={isLoading}
              disabled={!canSubmit}
            />
          </div>
        </Form>
      </StyledDiv>
    </StyledContainer>
  )
}

export default ResetPassword
